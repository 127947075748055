'use client';

import constant from '@/utils/constant';
import { useState } from 'react';
import Link from 'next/link';
import { FashMainLogo } from '@/assets/img/ImgList';
import Image from 'next/image';
import styles from '@/assets/styles/components/Layout.module.scss';
import { FooterNavItems } from './Navitems';
import { FaTwitter, FaFacebook, FaInstagram, FaPlus, FaLinkedin } from 'react-icons/fa6';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.group1}>
          <nav>
            {FooterNavItems.map((nav, i) => (
              <div className={styles.linkSet} key={i}>
                <p className={styles.title}>{nav.main}</p>

                {nav.subs.map((x, i) => (
                  <Link href={x.link} key={i}>
                    {x.title}
                  </Link>
                ))}
              </div>
            ))}
          </nav>
          <p className={styles.description}>
            With Fash, you can easily document and monitor your daily business transactions, send invoices and receipts to your customers, monitor
            debts owed to you, pay your employees, send and receive funds.
          </p>
        </div>
        <div className={styles.group2}>
          <Link href="">
            <Image src={FashMainLogo} alt="Mima Logo" width={90} height={50} className={styles.logo} />
          </Link>
          <p>
            33 TE Williams Street, Lagos
            <br />
            Or
            <br />
            745 John Adams CT,
            <br />
            Fate Texas 75189 <br />
            +(234)-81-3756-7029
            <br />
            +(234)-80-5043-9131
            <br />
            Hello@fash.style
          </p>
          <div className={styles.socialLinks}>
            <a href="https://x.com/_fashcreatives">
              <FaTwitter className={styles.socialIcon} />
            </a>
            <a href="https://www.instagram.com/_fashcreatives/">
              <FaInstagram className={styles.socialIcon} />
            </a>
            <a href="https://www.facebook.com/profile.php?id=61559341057334">
              <FaFacebook className={styles.socialIcon} />
            </a>
            <a href="https://www.linkedin.com/company/fash-creatives/about/">
              <FaLinkedin className={styles.socialIcon} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
