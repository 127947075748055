import { css } from "styled-components";

export const base = css`
  font-size: 1.8rem;
  font-family: inherit;
  color: var(--color-dark);
  padding-left: 2rem;
  height: 4.8rem;
  margin-top: 0.4rem;
  border-radius: 0.8rem;
  background-color: var(--color-white);
  border: 1px solid var(--color-dark);
  display: block;
  box-sizing: border-box;

  &:focus,
  &:focus-visible,
  &:focus-within {
    color: var(--color-dark);
    border: 2px solid var(--color-primary);
    outline: none;
    box-shadow: inset 2px 2px 3px #b0c1e2;
  }
`;

export const medium = css`
  width: 38rem;
  margin-bottom: 0.5rem;
`;
export const error = css`
  width: 38rem;
  border: 2px solid var(--color-error);
  outline: none;
  margin-bottom: 0.5rem;
`;

export const exchange = css`
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid var(--color-dark);
  width: 15rem;
  padding-left: 0;
  height: auto;

  &:focus,
  &:focus-visible,
  &:focus-within {
    border: none;
    border-radius: 0;
    color: var(--color-dark);
    border-bottom: 1px solid var(--color-primary);
  }
`;

export const wide = css`
  width: 60rem;

  @media only screen and (max-width: 31.25em) {
    width: 38rem;
  }
`;

export const form = css`
  width: 100%;
`;

export const form2 = css`
  width: 100%;
  height: 4.5rem;
  overflow-wrap: break-word;
  font-size: var(--default-font-small);
  padding: 1rem;
  padding-left: 2rem;
  border-radius: 1rem;
  border: 1px solid var(--color-dark);

  &:focus,
  &:focus-visible,
  &:focus-within {
    outline: none;
    border: 1px solid var(--color-primary);
  }
`;

export const mobile = css`
  width: 35rem;
`;

export const income = css`
  width: 17.9rem;
`;

export const resize = css`
  width: 35rem;
  margin-left: 8.3rem;
  margin-bottom: 2rem;
`;

export const message = css`
  width: 38rem;
  height: 12rem;
  overflow-wrap: break-word;
  hyphens: auto;
  padding: 2rem;
`;

export const type1 = css`
  width: 38rem;
  overflow-wrap: break-word;
  padding-left: 2rem;

  &:focus,
  &:focus-visible,
  &:focus-within {
    outline: none;
  }
`;

export const type2 = css`
  width: 100%;
  min-height: 17rem;
  max-height: 50rem;
  overflow-wrap: break-word;
  font-size: var(--default-font-small);
  padding: 1rem;
  padding-left: 2rem;
  border-radius: 1rem;
  border: 1px solid var(--color-primary);

  &:focus,
  &:focus-visible,
  &:focus-within {
    outline: none;
    border: 1px solid var(--color-primary);
  }
`;

export const type3 = css`
  width: 100%;
  min-height: 10rem;
  max-height: 25rem;
  overflow-wrap: break-word;
  font-size: var(--default-font-small);
  padding: 1rem;
  padding-left: 2rem;
  border-radius: 1rem;
  border: 1px solid var(--color-dark);

  &:focus,
  &:focus-visible,
  &:focus-within {
    outline: none;
    border: 1px solid var(--color-primary);
  }
`;

export const textArea = css`
  font-size: 1.8rem;
  font-family: inherit;
  color: var(--color-dark);
  width: 38rem;
  min-height: 6rem;
  max-height: 50rem;
  margin-top: 0.5rem;
  border-radius: 1rem;
  background-color: var(--color-white);
  border: 1px solid var(--color-dark);
  display: block;
  box-sizing: border-box;
  padding: 2rem;
  line-height: 3rem;

  &:focus,
  &:focus-visible,
  &:focus-within {
    color: var(--color-dark);
    border: 1px solid var(--color-primary);
  }

  &::-webkit-scrollbar {
    width: 1rem;
    background-color: var(--color-white);
    border-radius: 0 3rem 3rem 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-grey);
    border-radius: 3rem;
  }
`;

export const tagInput = css`
  font-size: 1.4rem;
  font-family: inherit;
  color: var(--color-grey);
  margin-top: 0.5rem;
  background-color: var(--color-white);
  display: block;
  box-sizing: border-box;
  width: 80%;
  outline: none;
  border: none;
  padding: 1rem 1rem;

  &:focus,
  &:focus-visible,
  &:focus-within {
    outline: none;
    border: none;
  }
`;

export const divBase = css`
  margin-bottom: 2rem;
`;

export const innerDivBase = css`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
  width: 100%;
  border: 1px solid var(--color-primary);
  border-radius: 1rem;
  padding: 1rem;
  min-height: 4rem;

  & > :not(:last-child) {
    margin-right: 1rem;
  }

  &:focus,
  &:focus-visible,
  &:focus-within {
    color: var(--color-dark);
    outline: none;
    border: 1px solid var(--color-primary);
  }
`;

export const sideBase = css`
  display: flex;
`;

export const pwIcon = css`
  position: relative;

  & > :nth-child(2) {
    position: absolute;
    bottom: 0.8rem;
    right: 0;
  }
`;
