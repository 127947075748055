import { competitionStore, userStore } from '../stores';
import { apiRequest } from '../utils/useAPIRequest';
import constant from '../utils/constant';
import { Data, IControllerResponseDTO, IDefaultObject } from '@/interfaces';
import { useMemo } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { parseRequestQueryParamsIntoSearchParams } from '@/utils/utils';
import { queryClient } from '@/app/ReactQueryProvider';

export const GET_COMPETITIONS = 'getCompetitions';
export const GET_PARTICIPANTS = 'getParticipants';
export const GET_PARTICIPANT = 'getParticipant';

const useGetCompetitions = (query: Data) => {
  const encodedQuery = parseRequestQueryParamsIntoSearchParams(query).toString()
  return useQuery({
    queryKey: [GET_COMPETITIONS, encodedQuery],
    queryFn: () => {
      return apiRequest({
        url: `/competition/competitions?${encodedQuery}`,
        method: 'get',
      });
    },
  });
};

const useGetParticipants = (query: Data) => {
  const encodedQuery = parseRequestQueryParamsIntoSearchParams(query).toString()
  return useQuery({
    queryKey: [GET_PARTICIPANTS, query],
    queryFn: () => {
      return apiRequest({
        url: `/competition/participants?${encodedQuery}`,
        method: 'get',
      });
    },
  });
};
const useGetAllVotes = (query: Data) => {
  const encodedQuery = parseRequestQueryParamsIntoSearchParams(query).toString()
  return useQuery({
    queryKey: [GET_PARTICIPANTS, query],
    queryFn: () => {
      return apiRequest({
        url: `/competition/votes?${encodedQuery}`,
        method: 'get',
      });
    },
  });
};

const useGetParticipant = (id: string) => {
  return useQuery({
    queryKey: [GET_PARTICIPANT, id],
    queryFn: () => {
      return apiRequest({
        url: `/competition/participant?id=${id}`,
        method: 'get',
      });
    },
  });
};



const register = async (payload: IDefaultObject): Promise<IControllerResponseDTO> => {
  const { status, errorMessage, data, errorCode } = await apiRequest<Data>({
    url: '/businesses/register-competition',
    method: 'post',
    payload,
  });

  if (status === constant.Success) {
    const { userId } = (data || {}) as Data;
    userStore.setUserId(userId);
    return { status };
  }

  return { status, errorMessage };
};
const vote = async (payload: IDefaultObject): Promise<IControllerResponseDTO> => {
  const { status, errorMessage, data, errorCode } = await apiRequest<Data>({
    url: '/competition/create-vote-invoice',
    method: 'post',
    payload,
  });

  if (status === constant.Success) {
    return { status, data };
  }

  return { status, errorMessage };
};

export const useVote = () => {
  return useMutation({
    mutationKey: ["vote"],
    mutationFn: async (payload: IDefaultObject) => {
      const { status, errorMessage, data, errorCode } = await apiRequest<Data>({
        url: '/competition/create-vote-invoice',
        method: 'post',
        payload,
      });
      if (status === constant.Success) {
        queryClient.invalidateQueries({ queryKey: [GET_PARTICIPANTS] });
        queryClient.invalidateQueries({ queryKey: [GET_PARTICIPANT, payload.participant] });
      }
      return { status, errorMessage, data, errorCode };
    },
  });
  
}



const uploadDocument = async (payload: any): Promise<IControllerResponseDTO> => {
  const { status, errorMessage, data } = await apiRequest<Data>({
    url: "/businesses/documents/upload",
    method: "post",
    payload,
    hasImageUpload: true,
  });
  if (status === constant.Success) {
    return { status: constant.Success, data };
  }
  return { errorMessage, status, data };
};

const CompetitionController = {
  register,
  uploadDocument,
  vote,
  useGetCompetitions,
  useGetParticipants,
  useGetParticipant,
  useGetAllVotes,
};

export default CompetitionController;
