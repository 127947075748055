'use client';

import styles from '../../assets/styles/components/CountDown.module.scss';
import React, { FC } from 'react';
import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';
import * as inputStyles from './styles';
import { useController } from 'react-hook-form';

interface MimaTextAreaProps {
  labelTitle?: string;
  placeholder: string;
  defaultValue?: string | number;
  id?: string;
  name: string;
  disabled?: boolean;
  width?: number; // Changed `any` to `number` for clarity.
  height?: number;
  mt?: number;
  mr?: number;
  mb?: number;
  ml?: number;
  error?: string | null; // Explicit type for error.
  touched?: boolean;
  fontSize?: number;
  note?: string;
  control: any; // Type for `control` can be improved if the form type is available.
  required?: boolean;
}

const MimaTextArea: FC<MimaTextAreaProps> = ({
  labelTitle,
  placeholder,
  disabled = false,
  width,
  height,
  id,
  name,
  mt,
  mr,
  mb,
  ml,
  touched,
  error,
  fontSize,
  note,
  control,
  required = false,
  defaultValue = '',
}) => {
  const {
    field: { value, onChange, onBlur },
  } = useController({
    name,
    control,
    defaultValue,
    rules: { required },
  });

  // Resizes the textarea dynamically as input changes
  const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.currentTarget.style.height = 'inherit'; // Reset height to calculate new height
    e.currentTarget.style.height = `${Math.min(e.currentTarget.scrollHeight, 500)}px`; // Max height of 500px
  };

  return (
    <DivSkeleton mt={mt} mb={mb} mr={mr} ml={ml}>
      {labelTitle && (
        <label htmlFor={id} className={styles.form__label}>
          {labelTitle}
        </label>
      )}
      <TextAreaSkeleton
        placeholder={placeholder}
        onBlur={onBlur}
        disabled={disabled}
        onChange={onChange}
        onInput={handleInput} // Updated from onKeyDown
        value={value}
        id={id}
        name={name}
        width={width}
        height={height}
        fontSize={fontSize}
      />
      {note && <small className={styles.note}>{note}</small>}
      {error && touched && <div className={styles.error}>{error}</div>}
    </DivSkeleton>
  );
};

// Styled-components for the textarea
const TextAreaSkeleton = styled.textarea<{
  width?: number;
  height?: number;
  fontSize?: number;
}>`
  ${inputStyles.textArea};

  min-height: 60px; // Default minimum height
  ${(props) => (props.width ? `width: ${props.width}rem;` : 'width: 100%;')};
  ${(props) => (props.fontSize ? `font-size: ${props.fontSize}rem;` : 'font-size: 1rem;')};
  ${(props) => (props.height ? `height: ${props.height}rem;` : 'height: auto;')};

  resize: none; // Prevent manual resizing as autosizing is handled
  overflow: hidden; // Hide scrollbars during resizing
`;

// Wrapper div for spacing
const DivSkeleton = styled.div<{
  mt?: number;
  mr?: number;
  mb?: number;
  ml?: number;
}>`
  ${inputStyles.divBase};
  ${(props) => (props.mt ? `margin-top: ${props.mt}rem;` : '')};
  ${(props) => (props.mr ? `margin-right: ${props.mr}rem;` : '')};
  ${(props) => (props.mb ? `margin-bottom: ${props.mb}rem;` : '')};
  ${(props) => (props.ml ? `margin-left: ${props.ml}rem;` : '')};
`;

export default MimaTextArea;
